import * as React from "react";
import { graphql, Link, HeadFC } from "gatsby";
import Layout from "../components/layout";
import PostBlock from "../components/postblock";
import Footer from "../components/footer";

const Category = ({ pageContext, data }: { pageContext: any; data: any }) => {
  const currentCategory = pageContext.currentCategory;
  const Postlist = data.allSanityPost.nodes;
  return (
    <main>
      <Layout></Layout>
      <section className="flex flex-col justify-center items-center py-36 px-24">
        <h1 className="text-5xl uppercase font-black underline">
          This is <span className="text-rosacorssa">{currentCategory}</span>
        </h1>
        <ul className="flex gap-4 flex-wrap justify-center items-center py-4">
          {Postlist.map((node: any) => {
            return (
              <li className="flex-grow basis-[50rem]">
                <PostBlock node={node}></PostBlock>
              </li>
            );
          })}
        </ul>
      </section>
      <Footer></Footer>
    </main>
  );
};

export const Head: HeadFC = ({ pageContext }: { pageContext: any }) => (
  <title>{pageContext.currentCategory}</title>
);

export const query = graphql`
  query ($currentCategory: String) {
    allSanityPost(
      filter: { categories: { elemMatch: { title: { eq: $currentCategory } } } }
      sort: { fields: publishedAt, order: DESC }
    ) {
      nodes {
        authors {
          author {
            slug {
              current
            }
            name
          }
        }
        categories {
          title
          slug {
            current
          }
        }
        slug {
          current
        }
        title
        publishedAt(formatString: "DD.MM.YYYY HH:mm")
        mainImage {
          asset {
            gatsbyImageData
          }
          alt
        }
        _id
        _rawExcerpt
      }
    }
  }
`;
export default Category;
