import * as React from "react";
import { Link } from "gatsby";
import { PortableText } from "@portabletext/react";
import { GatsbyImage } from "gatsby-plugin-image";

const PostBlock = ({ node }: { node: any }) => {
  var SlyS = [
    "New in",
    "On right now",
    "This is happening in",
    "Can you believe this?",
    "WOW!",
    "Read this in",
    "People's choice in",
  ];
  var Sly = SlyS[Math.floor(Math.random() * SlyS.length)];
  return (
    <Link to={`/posts/${node.slug.current}`} className="group">
      <article
        className="flex flex-row text-richblack gap-1 transition-all duration-500 justify-between p-10 rounded-md
                group-hover:bg-richblack group-hover:text-platinum"
      >
        <section className="flex flex-col justify-between">
          <Link to={`/tags/${node.categories[0].slug.current}`}>
            <h3 className="transition-all duration-500 text-shadowblue group-hover:text-bdazzledblue flex flex-row gap-1 items-center">
              {Sly} - {node.categories[0].title}
            </h3>
          </Link>
          <h1 className="text-4xl font-black">{node.title}</h1>
          <PortableText value={node._rawExcerpt}></PortableText>
          <Link to={`/editor/${node.authors[0].author.slug.current}`}>
            <h2 className="transition-all duration-500 text-shadowblue group-hover:text-bdazzledblue">
              Written by {node.authors[0].author.name}, at {node.publishedAt}
            </h2>
          </Link>
        </section>
        <GatsbyImage
          image={node.mainImage.asset.gatsbyImageData}
          alt={node.mainImage.alt}
          class="w-44 rounded-md"
        ></GatsbyImage>
      </article>
    </Link>
  );
};

export default PostBlock;
