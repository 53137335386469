import * as React from "react";

const Form = () => {
  const onSubmit = (event: any) => {
    event.preventDefault();
    alert("感謝您現在提交的信息");
    event.target.reset();
  };
  return (
    <form
      onSubmit={onSubmit}
      className="flex flex-col justify-center items-center gap-4"
    >
      <h1 className="underline text-platinum">Contact us!</h1>
      <input type="text" required placeholder="Name" className="rounded-md" />
      <input
        type="email"
        required
        placeholder="E-mail"
        className="rounded-md"
      />
      <label htmlFor="choosec" className="underline text-platinum">
        Choose your color!
      </label>
      <input type="color" name="choosec" required />
      <button
        type="submit"
        className="bg-platinum rounded-md p-1 border-bdazzledblue border-2 transition-all duration-300 hover:scale-105 font-semibold"
      >
        Submit
      </button>
    </form>
  );
};

export default Form;
